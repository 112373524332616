<template>
  <div>
    <el-form
      @submit.native.prevent="submitRequest"
      class="inspection-request"
      :rules="rules"
      v-loading="loading"
      :inline="true"
      :model="formData"
      ref="formInspectionNew"
    >
      <div v-if="lead.first_name">
        <h6 class="heading-small text-muted mb-4">
          New Inspection Request
        </h6>
        <div>
          <div class="row">
            <div class="col-lg-10">
              <h3 v-if="isEventTypeWedding">
                <span class="text-muted">For</span> {{ lead.first_name }}
                {{ lead.last_name }} & {{ enquiry && enquiry.partners_first_name }}
                {{ enquiry && enquiry.partners_last_name }}
              </h3>
              <h3 v-else>
                <span class="text-muted">For</span> {{ lead.first_name }}
                {{ lead.last_name }}
              </h3>
              <small class="text-muted">Event Date: {{ enquiry && enquiry.event_date | moment("DD MMM YYYY") }}</small>
            </div>
          </div>
        </div>
      </div>

      <hr />
      <h6 class="heading-small text-muted mb-4">
        <i class="ni ni-building"></i> 
        <span  v-if="shortlists">
          Confirm Venues 
        </span>
        <span  v-if="venue && venue.id">
          Venue
        </span>
      </h6>
      <div class="row">
        <div class="mb-3 text-xs" :class="(venue && venue.id) ? `col-lg-3` : `col-lg-2`"><strong>VENUE</strong></div>
        <div class="col-lg-2 mb-3 text-xs"><strong>SPACE</strong></div>
        <div class="col-lg-3 mb-3 text-xs"><strong>TIME/DATE</strong></div>
        <div class="col-lg-2 mb-3 text-xs"><strong>STATUS</strong></div>
        <div class="col-lg-1 mb-3 text-xs" :class="(venue && venue.id) ? `col-lg-2 text-center` : `col-lg-1`"><strong>SEND MAIL</strong></div>
        <div class="col-lg-2 mb-3 text-xs" v-if="! (venue && venue.id)"><strong>SYNC</strong></div>
      </div>
      <div class="row"  v-if="venue && venue.id">
        <div class="col-lg-3 mb-3 inspectionvenue">
          {{ venue.name }}
        </div>
        <div class="col-lg-2 select-a-space-borderless">
          <el-select
            v-model="isSpaceSelected[venue.id]" class="selectinspectionspace" placeholder="Space" size="mini"
            @change="spaceChanged(venue.id)">
            <el-option
              key="-11" label="- Entire Venue -"
              value="default_venue_timing"
              class="entire"
            >
            </el-option>
            <el-option
              v-for="(room, room_name) in venue.rooms"
              :key="room_name"
              :label="room_name"
              :value="room_name">
            </el-option>
          </el-select>
        </div>
        <div class="col-lg-3">
          <base-button
            type="secondary"
            native-type="button"
            class="date_and_time_select_button"
            size="sm"
            @click="showSelectDateTime(venue)"
            v-if="isSpaceSelected[venue.id]"
          >
            Select time/date
          </base-button>
          <span class="text-xs d-block">
            {{showSelecteDateTime(venue)}}
          </span>
        </div>
        <div class="col-lg-2 mb-3 select-a-space-borderless">
          <el-select
            v-model="formData.status[venue.id]" placeholder="Status" size="mini" >
            <el-option
              :key="InspectionService.INSPECTION_STATUS_REQUESTED"
              label="Requested"
              :value="InspectionService.INSPECTION_STATUS_REQUESTED"
            >
            </el-option>
            <el-option
              :key="InspectionService.INSPECTION_STATUS_CONFIRMED"
              label="Confirmed"
              :value="InspectionService.INSPECTION_STATUS_CONFIRMED"
            >
            </el-option>
          </el-select>
        </div>
        <div class="col-lg-2 mb-3 text-center">
          <base-checkbox v-model="formData.send_mail[venue.id]">
          </base-checkbox>
        </div>
      </div>
      <div class="row" v-for="venue in shortlists" :key="venue.id" v-if="shortlists && shortlists.length">
        <div class="col-lg-2 mb-3 inspectionvenue">
          <base-checkbox v-model="formData.venues[venue.id]">
            {{ venue.name }}
          </base-checkbox>
        </div>
        <div class="col-lg-2 select-a-space-borderless">
          <el-select
            v-model="isSpaceSelected[venue.id]" class="selectinspectionspace" placeholder="Space" size="mini"
            @change="spaceChanged(venue.id)">
            <el-option
              key="-1" label="- Entire Venue -"
              value="default_venue_timing"
              class="entire"
            >
            </el-option>
            <el-option
              v-for="room in venue.rooms"
              :key="room"
              :label="room"
              :value="room">
            </el-option>
          </el-select>
        </div>
        <div class="col-lg-3">
          <base-button
            type="secondary"
            native-type="button"
            size="sm"
            class="selectinspectiondate"
            @click="showSelectDateTime(venue)"
            v-if="isSpaceSelected[venue.id]"
          >
            time/date
          </base-button>
          <span class="text-xs">
            {{showSelecteDateTime(venue)}}
          </span>
        </div>
        <div class="col-lg-2 mb-3 select-a-space-borderless">
          <el-select
            v-model="formData.status[venue.id]" placeholder="Status" size="mini" >
            <el-option
              :key="InspectionService.INSPECTION_STATUS_REQUESTED"
              label="Requested"
              :value="InspectionService.INSPECTION_STATUS_REQUESTED"
            >
            </el-option>
            <el-option
              :key="InspectionService.INSPECTION_STATUS_CONFIRMED"
              label="Confirmed"
              :value="InspectionService.INSPECTION_STATUS_CONFIRMED"
            >
            </el-option>
          </el-select>
        </div>
        <div class="col-lg-1 mb-3 text-center">
          <el-checkbox v-model="formData.send_mail[venue.id]">
          </el-checkbox>
        </div>
        <div class="col-lg-2 mb-3">
          <VenueAvailabilityBadge :venue="venue" />
        </div>
      </div>
      <hr />
      <h6 class="heading-small text-muted mb-4">
        <i class="ni ni-building"></i> Additional Details
      </h6>

      <div class="row">
        <div class="col-lg-12">
          <div class="form-group">
            <label class="form-control-label">Add Message</label>
            <textarea
              rows="6"
              class="form-control"
              id="details_text_area"
              v-model="formData.message"
              placeholder=""
            >
          </textarea>
          </div>
        </div>
      </div>

      <input
        type="hidden"
        rows="6"
        class="form-control"
        :value="formData.date"
        placeholder=""
      />
      <base-button
        type="primary"
        native-type="submit"
        id="submit_new_inspection_request"
        class="my-4"
        :disabled="checkCheckboxes"
      >
        Send Request
      </base-button>
    </el-form>


    <el-dialog
      title="Select a Date & Time"
      width="640px"
      v-loading="loading"
      :visible.sync="showSelectDateTimeModel"
      v-if="showSelectDateTimeModel"
      :append-to-body="true"
    >
      <div class="row">
        <InspectionChecklist v-if="inspectionChecklistCompletionRequired && !checkListCompleted" :venue="selectedVenue" @complete="checkListCompleted = true" />
        <SelectInspectionDate v-if="! inspectionChecklistCompletionRequired ? true : checkListCompleted" :venue="selectedVenue" :availability="selectedVenuesAvailability" @confirm="setSelectedVenueTime"/>
      </div>

    </el-dialog>
  </div>
</template>
<script>
import InspectionService from "@/services/inspection.service";
import InspectionChecklist from "@/views/Widgets/Inspections/InspectionChecklist.vue";
import SelectInspectionDate from "@/views/Widgets/Inspections/SelectInspectionDate.vue";
import VenueAvailabilityBadge from "@/views/Widgets/Inspections/VenueAvailabilityBadge.vue";
import { DatePicker, dialog, Form, FormItem, Option, Select, TimeSelect, Checkbox } from "element-ui";
import moment from 'moment';
import momenttz from 'moment-timezone';
import timezoneMixin from '@/helpers/timezoneMixin';

export default {
  name: "new-inspection-form",
  props: {
    lead: {
      required: true,
    },
    enquiry: {
      required: true,
    },
    shortlists: {
      required: false,
    },
    venue_id: {
      required: false,
    },
  },
  components: {
    [DatePicker.name]: DatePicker,
    [FormItem.name]: FormItem,
    [Form.name]: Form,
    [TimeSelect.name]: TimeSelect,
    [Select.name]: Select,
    [Option.name]: Option,
    [dialog.name]: dialog,
    [Checkbox.name]: Checkbox,
    SelectInspectionDate,
    InspectionChecklist,
    VenueAvailabilityBadge,
  },
  data(){
    return {
      loading: true,
      checkListCompleted: false,
      formData: {
        venues: {},
        times: {},
        timezones: {},
        send_mail: {},
        message: '',
        spaces: {},
        status: {},
      },
      pickerData: {
        date: {},
        time: {},
      },
      pickerOptions: {
        disabledDate(time) {
          // Disable all dates prior today
          return time.getTime() + 24 * 60 * 60 * 1000 < Date.now();
        },
      },
      rules: {
        date: [
          {
            type: "date",
            required: true,
            message: "Please pick a date",
            trigger: "blur",
          },
        ],
      },
      inspection_timezone: "",
      showSelectDateTimeModel: false,
      selectedVenue:{},
      selectedVenuesAvailability: {},
      isSpaceSelected: {},
      InspectionService,
    };
  },
  async mounted() {
    if (this.venue_id) {
      await this.$store.dispatch("venues/getSingle", {
        lead: this.lead.id,
        id: this.venue_id,
      });
      this.$set(this.formData.venues, this.venue_id, true);
    }
    this.loading = false;
  },
  updated() {
    if (!this.inspection_timezone) {
      this.inspection_timezone = this.lead.timezone;
    }
  },
  computed: {
    today() {
      return moment().format();
    },
    venue() {
      return this.$store.getters["venues/getVenueById"](this.venue_id);
    },
    checkCheckboxes() {
      let isGood = true;
      let selectedLeastOne = false;
      for (const key in this.formData.venues) {
        if (this.formData.venues[key] === true) {
          selectedLeastOne = true;
        }
        if ((this.formData.venues[key] === true && !this.formData.times[key])) {
          isGood = false;
        }
        if ((this.formData.venues[key] === true && !this.formData.spaces[key])) {
          isGood = false;
        }
      }
      if (!selectedLeastOne) {
        isGood = false;
      }
      return !isGood;
    },
    isEventTypeWedding() {
      return this.enquiry?.eventType?.toLowerCase() === 'wedding';
    },
    inspectionChecklistCompletionRequired() {
        return this.enquiry?.event_type?.toLowerCase() !== 'corporate';
    }

  },
  mixins: [timezoneMixin],
  methods: {
    spaceChanged(venue_id) {
      this.$set(this.formData.times, venue_id, undefined);
      this.$set(this.formData.timezones, venue_id, undefined);
    },
    getConvertedTimes(times, fromTimezone, toTimezone) {
      let temp = {};
      Object.keys(times).forEach(function (key){
        var date = moment(key);
        if (key && date.isValid()) {
          times[key].forEach((unavailable_times) => {
            let unavailable_start = momenttz.tz(moment(key + ' ' + unavailable_times.start, 'YYYY-MM-DD hh:mm A').format('YYYY-MM-DD HH:mm'), fromTimezone);
            unavailable_start = unavailable_start.tz(toTimezone);
            let unavailable_end = momenttz.tz(moment(key + ' ' + unavailable_times.end, 'YYYY-MM-DD hh:mm A').format('YYYY-MM-DD HH:mm'), fromTimezone);
            unavailable_end = unavailable_end.tz(toTimezone); 
            if (temp[key]  === undefined) {
              temp[key] = [];
            }
            temp[key].push({
              start: moment(unavailable_start).format('hh:mm A'),
              end: moment(unavailable_end).format('hh:mm A'),
            });
          });
        } 
      });
      return temp;
    },
    showSelectDateTime(venue) {
      this.checkListCompleted = false; 
      this.showSelectDateTimeModel = true;
      this.selectedVenue = venue;
      let space = this.isSpaceSelected[venue.id];
      let availability = venue.availability ? venue.availability[space] : [];
      if (!availability || !availability.specified_availabilities) {
        let availability_temp = availability;
        availability = venue.availability && venue.availability['default_venue_timing'] ? venue.availability['default_venue_timing'] : [];
        let unavailable_temp = this.getConvertedTimes(availability_temp.unavailable, availability_temp.timezone, venue.availability['default_venue_timing'].timezone);
        availability.unavailable = {...unavailable_temp, ...availability.unavailable};
      }
      this.selectedVenuesAvailability = availability;
    },
    submitRequest() {
      if (this.venue_id) {
        this.$refs["formInspectionNew"].validate(async (valid) => {
          if (valid) {
            this.$emit("submitRequest", this.formData);
            this.resetForm();
          }
        });
      } else {
        this.$emit("submitRequest", this.formData);
        this.resetForm();
      }
    },
    resetForm() {
      this.formData.venues = {};
      this.formData.times = {};
      this.pickerData.date = {};
      this.pickerData.time = {};
      this.pickerData.spaces = {};
    },
    setSelectedVenueTime(data) {
      let space = this.isSpaceSelected[data.venue_id];
      this.$set(this.formData.times, data.venue_id, data.date);
      this.$set(this.formData.timezones, data.venue_id, data.timezone);
      this.$set(this.formData.venues, data.venue_id, true);
      this.$set(this.formData.spaces, data.venue_id, space);
      if (this.formData.status[data.venue_id] !== InspectionService.INSPECTION_STATUS_CONFIRMED) {
        this.$set(this.formData.status, data.venue_id, InspectionService.INSPECTION_STATUS_REQUESTED);
      }
      this.showSelectDateTimeModel = false;
    },
    showSelecteDateTime(venue) {
      if (!this.formData.times[venue.id]) {
        return '';
      }
      let dateObj = moment(this.formData.times[venue.id], 'YYYY-MM-DD hh:mm A');
      let date = moment.tz(dateObj.format('YYYY-MM-DD HH:mm'), this.formData.timezones[venue.id]);
      return date.format("ddd, MMM Do YYYY, h:mm a z");
    },
  },
  watch: {
    'formData.venues': {
      handler(newVenues) {
        Object.keys(newVenues).forEach(venueId => {
          if (newVenues[venueId] === true) {
            // If venue is selected and send_mail is not set for this venue
            if (this.formData.send_mail[venueId] === undefined) {
              // Set it to true by default
              this.$set(this.formData.send_mail, venueId, true);
            }
            // If it's already set (true or false), leave it as is
          } else {
            // If venue is deselected, remove it from send_mail
            if (this.formData.send_mail[venueId] !== undefined) {
              this.$delete(this.formData.send_mail, venueId);
            }
          }
        });
      },
      deep: true
    }
  }
};
</script>
<style>
.select-a-space-borderless .el-input__inner {
  border: none !important;
  padding-left: 0 !important;
}
.select-a-space-borderless .el-input--mini .el-input__inner {
  height: 28px !important;
}



</style>